import React from "react";
import {HiOutlineAtSymbol} from "react-icons/hi";
import "../css/utils.css";

export const Footer = () => {
    return (
        <footer>
            <div className={"contacts"}>
                <h3>Kontaktutje nás</h3>
                <a href="mailto:cafenero2022@gmail.com"><HiOutlineAtSymbol />cafenero2022@gmail.com</a>
                <a href="tel:733171883">733 171 883</a>
                <a href="https://www.instagram.com/cafenero2022/" target={"_blank"} rel="noopener noreferrer">Instagram</a>
            </div>

            <div className={"contacts"}>
                <h3>Adresa</h3>
                <span>Café Nero,Matiční 2740-20, Moravská Ostrava</span>
                <span>vchod z boku budovy školy.</span>
                <span>free wi-fi</span>
                <span>parkování</span>
            </div>
            <div className={"image-address"}>
                <img src="/img/img.png" alt={"Mapa"}/>
            </div>
        </footer>
    );
}
