import React, {useState, useEffect, useRef} from 'react';
import '../../css/admin.css';
import { Link } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAllProducts } from "../../hooks/useAllProducts";
import { PaginatorTemplate } from "primereact/paginator";
import { useAllWeddings } from "../../hooks/useAllWeddings";
import {Catering, Event, Product, Order, Item, User, School, Image} from "../../utils/interfaces";
import useAllUsers from "../../hooks/useAllUsers";
import { Dropdown } from "primereact/dropdown";
import { useAccountTypes } from "../../hooks/useAccountTypes";
import {OrderTable} from "../../utils/OrderTable";
import useSchools from "../../hooks/useSchools";
import useOrders from "../../hooks/useOrders";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {HiOutlineTrash} from "react-icons/hi";

export default function AdminPage() {
    const [images, setImages] = useState<Image[]>([]);
    const toast = useRef<Toast>(null);
    const fileUploadRef = useRef<FileUpload>(null);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/images`);
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
            const data: Image[] = await response.json();
            setImages(data);
        } catch (error) {
            console.error('Error fetching images:', error);
            toast.current?.show({severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se načíst obrázky'});
        }
    };

    const onUpload = async (event: any) => {
        const file = event.files[0];
        const formData = new FormData();

        // Use the file's name (without extension) as the title
        const title = file.name.replace(/\.[^/.]+$/, "");

        formData.append('image', file);
        formData.append('title', title);

        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/images`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to upload image');
            }

            toast.current?.show({severity: 'success', summary: 'Úspěch', detail: 'Obrázek byl úspěšně nahrán'});
            fetchImages(); // Refresh the images list
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.current?.show({severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se nahrát obrázek'});
        }

        // Clear the FileUpload component
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    const deleteImage = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/images/${id}`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to delete image');
            }

            toast.current?.show({severity: 'success', summary: 'Úspěch', detail: 'Obrázek byl úspěšně smazán'});
            fetchImages(); // Refresh the images list
        } catch (error) {
            console.error('Error deleting image:', error);
            toast.current?.show({severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se smazat obrázek'});
        }
    };

    const imageUrl = process.env.REACT_APP_URL_IMAGE;

    // Generalized functions
    const itemImage = (data: Item) => {
        const imageSrc = data.image_small || data.image;
        const fullImageUrl = imageSrc?.startsWith('http') ? imageSrc : `${imageSrc}`;
        return (
            <div className="product-image-container">
                <img src={fullImageUrl} alt={data.name} className="product-image" />
            </div>
        );
    };

    const { orders: fetchedOrders } = useOrders();
    const [orders, setOrders] = useState<Order[]>([]);

    const { products: fetchedProducts } = useAllProducts();
    const [products, setProducts] = useState<Product[]>([]);

    const { weddings: fetchedWeddings } = useAllWeddings();
    const [weddings, setWeddings] = useState<Event[]>([]);


    const { users: fetchedUsers } = useAllUsers();
    const [users, setUsers] = useState<User[]>([]);

    const { accountTypes } = useAccountTypes();
    const accountTypeOptions = accountTypes.map((type) => ({ label: type, value: type }));

    const {schools: fetchedSchools} = useSchools();
    const [schools, setSchools] = useState<School[]>([]);

    useEffect(() => {
        setOrders(fetchedOrders);
    }, [fetchedOrders]);

    useEffect(() => {
        setProducts(fetchedProducts);
    }, [fetchedProducts]);

    useEffect(() => {
        setWeddings(fetchedWeddings.map(wedding => ({
            ...wedding,
            image: wedding.images[0].startsWith('http') ? wedding.images[0] : `${imageUrl}${wedding.images[0]}`,
            image_small: wedding.images_small[0].startsWith('http') ? wedding.images_small[0] : `${imageUrl}${wedding.images_small[0]}`,
            images_small: wedding.images_small.map(img => img.startsWith('http') ? img : `${imageUrl}${img}`),
            images: wedding.images.map(img => img.startsWith('http') ? img : `${imageUrl}${img}`)
        })));
    }, [fetchedWeddings, imageUrl]);


  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/users`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.current?.show({severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se načíst uživatele'});
    }
  };

    useEffect(() => {
        setUsers(fetchedUsers);
    }, [fetchedUsers]);

    useEffect(() => {
        setSchools(fetchedSchools);
    }, [fetchedSchools]);

    const addProductButton = (
        <span className="p-paginator-element">
            <Link className="p-button p-component" to="/admin/novy-produkt">Přidat další</Link>
        </span>
    );

    const addWeddingButton = (
        <span className="p-paginator-element">
            <Link className="p-button p-component" to="/admin/nova-svatba">Přidat další</Link>
        </span>
    );

    const addCateringButton = (
        <span className="p-paginator-element">
            <Link className="p-button p-component" to="/admin/novy-catering">Přidat další</Link>
        </span>
    );

    const addSchoolButton = (
        <span className="p-paginator-element">
            <Link className="p-button p-component" to="/admin/nova-skola">Přidat další</Link>
        </span>
    );

    const customPaginatorTemplate: PaginatorTemplate = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    };


    const itemName = (data: Item) => {
        return <Link to={`/admin/upravit-produkt/${data.id}`}>{data.name}</Link>;
    };

    const weddingName = (data: Event) => {
        return <Link to={`/admin/upravit-svatbu/${data.id}`}>{data.name}</Link>;
    }

    const cateringName = (data: Catering) => {
        return <Link to={`/admin/upravit-catering/${data.id}`}>{data.name}</Link>;
    }

    const itemPriceStandard = (data: Item) => {
        return data.price_standard ? <span>{data.price_standard} Kč</span> : null;
    };

    const itemPricePremium = (data: Item) => {
        return data.price_premium ? <span>{data.price_premium} Kč</span> : null;
    };

    const itemPrice = (data: Item) => {
        return data.price ? <span>{data.price} Kč</span> : null;
    };

    const name = (data: User) => {
        return <span>{data.first_name} {data.last_name}</span>
    }

    /*const schoolAddress = (data: School) => {
        return <span>{data.street} {data.city}</span>
    }*/

    console.log(schools);

    const setSchool = (props: any) => {
        if (props.rowData.account_type !== 'student') {
            return <span>-</span>;
        }

        return (
            <Dropdown
                value={props.value}
                options={schools}
                onChange={(e) => props.editorCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Vyberte školu"
            />
        );
    };

    const setAccountType = (props: any) => {
        return (
            <Dropdown
                value={props.rowData.account_type}
                options={accountTypeOptions}
                onChange={(e) => props.editorCallback(e.value)}
                placeholder="Vyberte typ účtu"
            />
        );
    }

    const onRowEditComplete = (e: any) => {
        let _users = [...users];
        let { newData, index } = e;

        _users[index] = newData;

        // Update user account type
        fetch(`${process.env.REACT_APP_URL}/api/users/${newData.id}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                account_type: newData.account_type,
                school_id: newData.school_id,
            }),
        })
        .then((response) => {
            if (!response.ok) {
                toast.current?.show({severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se uložit změny'});
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            // update the user from response of the server
            return response.json();
        }).then((data) => {
            _users[index] = data;
            setUsers(_users);
            toast.current?.show({severity: 'success', summary: 'Úspěch', detail: 'Změny byly uloženy'});
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
    const schoolBodyTemplate = (rowData: User) => {
        if (rowData.account_type !== 'student') {
          return <span>-</span>;
        }
        const school = schools.find(s => s.id === rowData.school_id);
        return school ? school.name : 'Není přiřazena';
    };

    return (
        <div className={"content justify-center admin"}>
            <div className={"p-card admin-title"}>
                <h1>Administrace</h1>
            </div>
            <Toast ref={toast} position={"bottom-right"} />
            <div className={"p-card"}>
                <h2>Správa dat</h2>
                <TabView>
                    <TabPanel header="Produkty">
                        <DataTable
                            value={products}
                            emptyMessage={"Žádné produkty"}
                            paginator
                            rows={5}
                            paginatorTemplate={customPaginatorTemplate}
                            paginatorRight={addProductButton}
                        >
                            <Column body={itemImage} className={"product-image-container"} header={"Obrázek"} />
                            <Column body={(data) => itemName(data)} header={"Název"} />
                            <Column body={itemPriceStandard} header={"Cena"} />
                            <Column body={itemPricePremium} header={"Velkooděratelská cena"} />
                            <Column field={"description"} className={"description"} header={"Popis"} />
                            <Column field={"type_names"} header={"Typ"} />
                        </DataTable>
                    </TabPanel>

                    <TabPanel header="Svatby">
                        <DataTable
                            value={weddings}
                            emptyMessage={"Žádné svatby"}
                            paginator
                            rows={10}
                            paginatorTemplate={customPaginatorTemplate}
                            paginatorRight={addWeddingButton}
                            className="custom-paginator"
                        >
                            <Column body={itemImage} className={"product-image-container"} header={"Obrázek"} />
                            <Column body={(data) => weddingName(data)} header={"Název"} />
                            <Column body={itemPrice} header={"Cena"} />
                            <Column field={"description"} className={"description"} header={"Popis"} />
                        </DataTable>
                    </TabPanel>

                </TabView>
            </div>

            <div className={"p-card"}>
                <h2>Objednávky</h2>
                <OrderTable orders={orders} />
            </div>

            <div className={"p-card"}>
                <h2>Uživatelé</h2>
                <DataTable
                    value={users}
                    emptyMessage={"Žádní uživatelé"}
                    paginator
                    rows={10}
                    editMode={"row"}
                    onRowEditComplete={onRowEditComplete}
                >
                    <Column
                        field={"name"}
                        header={"Jméno"}
                        body={name}
                    />
                    <Column
                        field={"email"}
                        header={"Email"}
                    />
                    <Column
                        field={"phone_number"}
                        header={"Telefonní číslo"}
                    />
                    <Column
                        field="school_id"
                        header="Škola"
                        body={schoolBodyTemplate}
                        editor={(props) => setSchool(props)}
                    />
                    <Column
                        field={"account_type"}
                        header={"Typ účtu"}
                        editor={(props) => setAccountType(props)}
                    />
                    <Column rowEditor headerStyle={{ width: '5%' }} bodyStyle={{ textAlign: 'center' }} />
                </DataTable>
            </div>

            <div className={"p-card"}>
                <h2>Školy</h2>
                <DataTable
                    value={schools}
                    emptyMessage={"Žádné školy"}
                    paginator
                    rows={10}
                    paginatorTemplate={customPaginatorTemplate}
                    paginatorRight={addSchoolButton}
                    className="custom-paginator"
                >
                    <Column field={"name"} header={"Název"} />
                    <Column field={"email_prefix"} header={"Email prefix"} />
                    <Column field={"address"} header={"Adresa"} />
                </DataTable>
            </div>

            <div className={"p-card"}>
                <h2>Galerie obrázků</h2>
                <FileUpload
                    ref={fileUploadRef}
                    name="image"
                    accept="image/*"
                    maxFileSize={1000000}
                    customUpload
                    uploadHandler={onUpload}
                    auto
                    chooseLabel="Vybrat obrázek"
                />
                <DataTable value={images} paginator rows={10}>
                    <Column field="title" header="Název" />
                    <Column header="Obrázek" body={(rowData: Image) => (
                        <img
                            src={`${process.env.REACT_APP_URL}/storage/${rowData.thumbnail_path}`}
                            alt={rowData.title}
                            style={{width: '100px'}}
                        />
                    )} />
                    <Column header="Akce" body={(rowData: Image) => (
                        <Button
                            icon={<HiOutlineTrash />}
                            className="p-button-danger p-button-rounded p-button-text"
                            onClick={() => deleteImage(rowData.id)}
                            tooltip="Smazat obrázek"
                        />
                    )} />
                </DataTable>
            </div>
        </div>
    );
}
