import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import "../css/userform.css";
import { validEmail } from "../validators/emailValidator";
import { formatPhone, validPhone } from "../validators/phoneValidator";

/*
* validateData - function to validate user input
* @param email - user email
* @param password - user password
* @param toast - toast component
* @param phone - user phone number, optional
* @returns boolean - true if all data is valid, false otherwise
*/
function validateData(email: string, password: string, toast: Toast, phone?: string, password2?: string) {
    if (!email || !password) {
        toast.show({ severity: 'error', summary: 'Chyba', detail: 'Všechna pole musí být vyplněna', life: 3000 });
        return false;
    }

    if (!validEmail(email)) {
        toast.show({ severity: 'error', summary: 'Chyba', detail: 'Neplatný email', life: 3000 });
        return false;
    }

    if (phone && !validPhone(phone)) {
        toast.show({ severity: 'error', summary: 'Chyba', detail: 'Neplatné telefonní číslo', life: 3000 });
        return false;
    }

    if (password.length < 8) {
        toast.show({ severity: 'error', summary: 'Chyba', detail: 'Heslo musí mít alespoň 8 znaků', life: 3000 });
        return false;
    }

    if (password2 && password !== password2) {
        toast.show({ severity: 'error', summary: 'Chyba', detail: 'Hesla se neshodují', life: 3000 });
        return false;
    }

    return true;
}

/*
* Login - component for user login
* @returns JSX.Element - login form
*/
export default function Login() {
    const navigate = useNavigate();
    const toast = useRef<Toast | null>(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    /*
    * API request to POST user data and login user on success
    */
    const apiRequest = (emailValue: string, passwordValue: string) => {
        if (!validateData(emailValue, passwordValue, toast.current as Toast)) {
            return;
        }
        // POST - ready to use when connected to backend
        const appUrl = process.env.REACT_APP_URL;
        fetch(`${appUrl}/api/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: emailValue,
                password: passwordValue
            })
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    navigate('/profil');
                });
            } else {
                toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Email a heslo se neshodují', life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Přihlášení se nezdařilo', life: 3000 });
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        apiRequest(email, password);
    };

    return (
        <>
            <Toast ref={toast} position="bottom-right" />

            <div className="content justify-center">
                <div className="p-card form-card">
                    <h1>Přihlásit se</h1>

                    <form className="user-form" onSubmit={handleSubmit}>
                        <FloatLabel>
                            <InputText onChange={e => setEmail(e.target.value)} className="text-input" id="email" />
                            <label htmlFor="email">E-mail</label>
                        </FloatLabel>

                        <div className="password-container">
                            <FloatLabel>
                                <Password
                                    onChange={e => setPassword(e.target.value)}
                                    className="text-input"
                                    id="password"
                                    toggleMask
                                    feedback={false}
                                />
                                <label htmlFor="password">Heslo</label>
                            </FloatLabel>
                            <Link className="m-left-auto" to="/prihlasit">Zapomenuté heslo</Link>
                        </div>

                        <button className="button p-button" type="submit">
                            Přihlásit
                        </button>
                    </form>
                    <p>Nemáš účet? <Link to="/registrovat">Zaregistruj se</Link></p>
                </div>
            </div>
        </>
    );
}

export function Register() {
    const navigate = useNavigate();
    const toast = useRef<Toast | null>(null);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateData(email, password1, toast.current as Toast, phone, password2)) {
            return;
        }
        const appUrl = process.env.REACT_APP_URL;
        // POST - ready to use when connected to backend
        fetch(`${appUrl}/api/users`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                first_name: firstname,
                last_name: lastname,
                email: email,
                phone_number: phone,
                password: password2
            })
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    navigate("/profil");
                });
            } else {
                toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Registrace se nezdařila', life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Chyba', detail: 'Registrace se nezdařila', life: 3000 });
        });
    };

    return (
        <>
            <Toast ref={toast} position="bottom-right" />

            <div className="content justify-center">
                <div className="p-card form-card">
                    <h1>Vytvořit účet</h1>

                    <form className="user-form" onSubmit={handleSubmit}>
                        <div className="name-container">
                            <FloatLabel>
                                <InputText
                                    className="text-input"
                                    id="firstname"
                                    onChange={e => setFirstname(e.target.value)}
                                />
                                <label htmlFor="firstname">Jméno</label>
                            </FloatLabel>

                            <FloatLabel>
                                <InputText
                                    className="text-input"
                                    id="lastname"
                                    onChange={e => setLastname(e.target.value)}
                                />
                                <label htmlFor="lastname">Přijmení</label>
                            </FloatLabel>
                        </div>

                        <div className="inputs-container">
                            <FloatLabel>
                                <InputText
                                    className="text-input"
                                    id="email"
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <label htmlFor="email">E-mail</label>
                            </FloatLabel>

                            <FloatLabel>
                                <InputText
                                    className="text-input"
                                    id="phone"
                                    onChange={e => setPhone(formatPhone(e.target.value))}
                                />
                                <label htmlFor="phone">Telefonní číslo</label>
                            </FloatLabel>
                        </div>

                        <div className="inputs-container">
                            <FloatLabel>
                                <Password
                                    className="password-input"
                                    id="password1"
                                    toggleMask
                                    feedback={false}
                                    onChange={e => setPassword1(e.target.value)}
                                />
                                <label htmlFor="password1">Heslo</label>
                            </FloatLabel>

                            <FloatLabel>
                                <Password
                                    className="password-input"
                                    id="password2"
                                    toggleMask
                                    feedback={false}
                                    onChange={e => setPassword2(e.target.value)}
                                />
                                <label htmlFor="password2">Potvrdit heslo</label>
                            </FloatLabel>
                        </div>

                        <button
                            className="button p-button"
                            type="submit"
                        >
                            Registrovat
                        </button>
                    </form>

                    <p>Máš účet? <Link to="/prihlasit">Přihlaš se</Link></p>
                </div>
            </div>
        </>
    );
}