import React, { useRef, useState, useEffect } from "react";
import { Card } from 'primereact/card';
import "../css/home.css"
import "../css/utils.css";
import { Galleria } from "primereact/galleria";
import { Link } from "react-router-dom";
import { DeferredContent } from "primereact/deferredcontent";

interface GalleryImage {
    id: string;
    title: string;
    description: string;
    path: string;
    thumbnail_path: string;
}

export default function Home() {
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const galleria = useRef<Galleria>(null);

    useEffect(() => {
        fetchGalleryImages();
    }, []);

    const fetchGalleryImages = async () => {
        try {
            const appUrl = process.env.REACT_APP_URL;
            const response = await fetch(`${appUrl}/api/images`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGalleryImages(data);
        } catch (error) {
            console.error("Failed to fetch gallery images:", error);
        }
    };

    /* --- CARD CONSTANTS --- */
    const weddingHeader = (
        <img src="/img/received_2791426547596365.jpeg" alt="svatba" />
    )

    const cateringHeader = (
        <img src="/img/received_1247914002030781.jpeg" alt="catering" />
    )

    const productsHeader = (
        <img src="/img/IMG_20230511_082424_579.jpg" alt="produkty" />
    )

    const footer = (link: string) => (
        <Link to={link} className={"button p-button"}> Zjistit více </Link>
    )

    const itemTemplate = (item: GalleryImage) => {
        const storageUrl = process.env.REACT_APP_URL_IMAGE;
        return <img src={`${storageUrl}${item.path}`} alt={item.title} style={{ width: '100%' }} />
    }

    const thumbnailTemplate = (item: GalleryImage) => {
        const storageUrl = process.env.REACT_APP_URL_IMAGE;
        return <img src={`${storageUrl}${item.thumbnail_path}`} alt={item.title} style={{ width: '100%' }} />
    }

    return (
        <>
            <div className={"justify-center content"}>
                <div className={"card-container home-cards"}>
                    <DeferredContent className="card">
                        <Card style={{lineHeight: "1.5rem"}} title="Chlebicky&Bagety" footer={footer("/produkty")} header={productsHeader}>
                            <p className="m-0">
                                Objevte naše lahodné bagetové chlebíčky, kde se snoubí křupavost čerstvé bagety s bohatstvím chutí pečlivě vybraných ingrediencí, připravené s láskou našimi zkušenými kuchaři pro každou příležitost.
                            </p>
                        </Card>
                    </DeferredContent>

                    <DeferredContent className="card">
                        <Card style={{lineHeight: "1.5rem"}} title="Svatby&Catering " footer={footer("/svatby")} header={weddingHeader}>
                            <p className="m-0">
                                Proměňte Váš velký den v nezapomenutelný zážitek s naší komplexní nabídkou svatebních služeb, zahrnující okouzlující prostory, vytříbené menu a profesionální koordinaci, vše pečlivě připravené našim zkušeným týmem.
                            </p>
                        </Card>
                    </DeferredContent>

                    <DeferredContent className="card">
                        <Card style={{lineHeight: "1.5rem"}} title="Dezerty" footer={footer("/dezerty")} header={cateringHeader}>
                            <p className="m-0">
                                Dopřejte svým hostům nezapomenutelný kulinářský zážitek s naším prvotřídním cateringem, nabízejícím širokou škálu menu od elegantních jednohubek po bohaté bufety, připravené z čerstvých lokálních surovin pro jakoukoliv událost.
                            </p>
                        </Card>
                    </DeferredContent>

                </div>
            </div>

            <div className={"about"}>
                <div className={"text"}>
                    <h1>O nás</h1>
                    <p>
                        Cafe Nero je rodinná kavárna zaměřena na cukrářskou a pekařskou výrobu. Připravujeme tradiční české koláče a moderní dezerty. Naše dezerty jsou bez umělých barviv a konzervantů. Všechny produkty, které nabízíme jsou z kvalitních surovin. Denně pro Vás připravujeme čerstvé bagety, panini, domácí limonády, dezerty, máslavou baklavu, lahodnou kávu.
                    </p>
                </div>

                <div className={"image"}>
                    <img src="/img/IMG_20220829_210511_564.jpg" alt=""/>
                </div>
            </div>

            <Galleria ref={galleria} value={galleryImages} numVisible={7} style={{ maxWidth: '850px' }}
                      activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                      circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

            <div className={"galleryContainer"}>
                <h2>Galerie</h2>
                <div className="gallery">
                    {
                        galleryImages && galleryImages.map((image, index) => {
                            let imgEl = <img src={`${process.env.REACT_APP_URL_IMAGE}${image.thumbnail_path}`} onClick={
                                () => {
                                    setActiveIndex(index);
                                    galleria.current?.show()
                                }
                            } alt={image.title} />
                            return (
                                <div className={"galleryImg"} key={image.id}>
                                    {imgEl}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}
