import React from 'react';
import { HiMinus, HiPlus } from "react-icons/hi";
import { useCart } from "../hooks/useCart";
import { Product } from "../utils/interfaces"; // Adjust the import path based on your project structure

interface ProductButtonProps {
    product: Product;
}

const ProductButton: React.FC<ProductButtonProps> = ({ product }) => {
    const { cart, handleProductAction } = useCart(); // Use the custom hook for cart management

    const cartItem = cart[product.id];
    const isMaxQuantity = cartItem && cartItem.quantity >= cartItem.maximum_quantity;

    return (
        <span className={"align-center"}>
            {
                cartItem?.quantity === 0 || cartItem?.quantity === undefined ? (
                    <button
                        onClick={() => handleProductAction({ type: 'ADD', product })}
                        className={"button p-button"}
                    >
                        Přidat do košíku
                    </button>
                ) : (
                    <>
                        <button
                            onClick={() => handleProductAction({ type: 'REMOVE', productId: product.id })}
                            className={"unset icon-button"}
                        >
                            <HiMinus />
                        </button>

                        <span className={"quantity-display"}>
                            {cartItem?.quantity ?? 0}
                        </span>

                        <button
                            onClick={() => handleProductAction({ type: 'ADD', product })}
                            className={"unset icon-button"}
                            disabled={isMaxQuantity}
                        >
                            <HiPlus />
                        </button>
                    </>
                )
            }
        </span>
    );
};

export default ProductButton;