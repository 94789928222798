import React, { useEffect, useState } from "react";
import Hero from "../utils/hero";
import { Card } from "primereact/card";
import "../css/products.css";
import { DeferredContent } from "primereact/deferredcontent";
import { MultiSelect } from "primereact/multiselect";
import { Link } from "react-router-dom";
import { Product } from "../utils/interfaces";
import { useAllDesserts } from "../hooks/useAllDesserts";
import ProductButton from "../utils/productButton";

export default function Desserts() {
    const { products: fetchedProducts, loading, error } = useAllDesserts(); // Use the custom hook for fetching products
    const [productTypes, setProductTypes] = useState<string[]>([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        setProducts(fetchedProducts);
        const types = Array.from(new Set(fetchedProducts.flatMap(product => product.type_names)));
        setProductTypes(types);
    }, [fetchedProducts]);

    const itemHeader = (product: Product) => (
        <Link to={`/produkty/${product.id}`} className="product-link">
            <div className="custom-header">
                <img src={product.image_small} alt={product.name} />
                <h2  className={"p-card-title"}>{product.name}</h2>
            </div>
        </Link>
    );


    return (
        <>
            <Hero
                title={"Produkty"}
                description={""}
            />

            <div className={"products"}>
                <div className={"card-container"}>
                    {loading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>Error: {error}</div>
                    ) : (
                        products
                            .filter(product => selectedProductTypes.length === 0 || product.type_names.some(type => selectedProductTypes.includes(type)))
                            .map((product, index) => (
                                <DeferredContent key={index} className={"product"}>
                                    <Card
                                        id={product.id}
                                        header={itemHeader(product)}
                                        subTitle={`${product.price} Kč`}
                                        footer={<ProductButton product={product} />} // Use ProductButton component
                                    />
                                </DeferredContent>
                            ))
                    )}
                </div>
            </div>
        </>
    );
}
